import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
import { theme } from '@uchiru/components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Uchiru Components components come with built-in access to our Uchiru theme. The `}<a parentName="p" {...{
        "href": "https://github.com/uchiru/components/blob/master/src/theme-preval.js"
      }}>{`theme file`}</a>{` contains an object which holds values for common variables such as color, fonts, box shadows, and more. Our theme file pulls many of its color and typography values from `}<a parentName="p" {...{
        "href": "https://github.com/uchiru/primitives"
      }}>{`uchiru-primitives`}</a>{`.`}</p>
    <p>{`Many of our theme keys correspond to system props on our components. For example, if you'd like to set the max width on a `}<inlineCode parentName="p">{`<Box>`}</inlineCode>{` set the `}<inlineCode parentName="p">{`maxWidth`}</inlineCode>{` prop to `}<inlineCode parentName="p">{`medium`}</inlineCode>{`: `}<inlineCode parentName="p">{`<Box maxWidth='medium'>`}</inlineCode></p>
    <p>{`In the background, `}<a parentName="p" {...{
        "href": "https://github.com/styled-system/styled-system"
      }}>{`styled-system`}</a>{` does the work of finding the `}<inlineCode parentName="p">{`medium`}</inlineCode>{` value from `}<inlineCode parentName="p">{`maxWidth`}</inlineCode>{` key in the theme file and applying the corresponding CSS.`}</p>
    <p>{`Our full theme can be found `}<a parentName="p" {...{
        "href": "https://github.com/uchiru/components/blob/master/src/theme-preval.js"
      }}>{`here`}</a>{`.`}</p>
    <h3>{`Custom Theming`}</h3>
    <p>{`Custom theming is an optional way to override the Uchiru values that control color, spacing, typography, and other aspects of our components.`}</p>
    <p>{`There are two ways to change the theme of Uchiru components:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`You can override the entire theme for an entire tree of components using the `}<inlineCode parentName="p">{`<ThemeProvider>`}</inlineCode>{` from `}<a parentName="p" {...{
            "href": "https://styled-components.com/"
          }}>{`styled-components`}</a>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import {Box, Button, Text, theme as uchiru} from '@uchiru/components'
import {ThemeProvider} from 'styled-components'

// a theme with custom spacing and font sizes
const theme = {
  ...uchiru,
  space: [0, 8, 16, 32, 64],
  fontSizes: [10, 12, 16, 24, 48]
}

// override
theme.colors.bodytext = '#111'

export default () => (
  <ThemeProvider theme={theme}>
    <Box color='bodytext' p={4}>
      <Text fontSize={4}>Hello, world!</Text>
    </Box>
  </ThemeProvider>
)
`}</code></pre>
        <p parentName="li"><strong parentName="p">{`⚠️ Note: `}<a parentName="strong" {...{
              "href": "https://styled-components.com/"
            }}>{`styled-components`}</a>{`'s `}<inlineCode parentName="strong">{`<ThemeProvider>`}</inlineCode>{` only allows exactly one child.`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can merge the Uchiru theme with your custom theme using Object.assign:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import {ThemeProvider} from \`styled-components\`
import {theme} from '@uchiru/components'

const customTheme = { ... }

const App = (props) => {
  return (
    <div>
      <ThemeProvider theme={Object.assign({}, theme, customTheme)}> // matching keys in customTheme will override keys in the Uchiru theme
        <div>your app here</div>
      </ThemeProvider>
    </div>
  )
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can theme individual components by passing the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` prop directly:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import {Text} from '@uchiru/components'

const theme = {
  colors: {
    magenta: '#f0f'
  }
}

export default () => (
  <Text theme={theme} color='magenta'>Hi, I'm magenta!</Text>
)
`}</code></pre>
        <p parentName="li"><strong parentName="p">{`☝️ This is an intentionally convoluted example, since you can use `}<inlineCode parentName="strong">{`<Text color='#f0f'>`}</inlineCode>{` out of the box.`}</strong></p>
      </li>
    </ol>
    <p>{`Read the `}<a parentName="p" {...{
        "href": "https://styled-system.com/#theming"
      }}>{`styled-system docs`}</a>{` for more information on theming in styled-system.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      